import {httpService} from "../http-service";

class PayParamService{

    /**
     * 获取支付参数列表
     */
     getPayParamList() {
        const url = "/payParam/getPayParamList";
        return httpService.post(url, {});

    }

    /**
     * 新增支付参数
     */
     addPayParam(data:any) {
        const url = "/payParam/addPayParam";
        return httpService.post(url, data);
    }

    /**
     * 修改支付参数
     */
     updatePayParam(data:any) {
        console.log("updatePayParam", data);
        const url = "/payParam/updatePayParam";
        return httpService.post(url, data);
    }
}

export const payParamService = new PayParamService();
