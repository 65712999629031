import {Button, Form, Input, message, Modal, Radio, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {mchService} from "../../../service/admin";


export const MchManage = () => {

    const [form] = Form.useForm()

    const [searchLoading, setSearchLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = useState<boolean>(false)
    const [mch, setMch] = useState<any>();

    useEffect(() => {
        search();
    }, []);

    const search = () => {
        setSearchLoading(true);
        mchService.list()
            .then(res => {
                const data = res.data;
                if (data.status === 0) {
                    setTableData(data.data)
                } else {
                    message.error(data.msg)
                }
                setSearchLoading(false);
            })
            .catch(err => {
                message.error("发生未知错误");
                setSearchLoading(true);
            })
    }


    const columns = [
        // {
        //     key: "id",
        //     dataIndex: "id",
        //     title: "数据库ID"
        // },
        {
            key: "mchId",
            dataIndex: "mchId",
            title: "商户号ID"
        },
        {
            key: "mchName",
            dataIndex: "mchName",
            title: "商户号名称"
        },
        {
            key: "description",
            dataIndex: "description",
            title: "商户号描述"
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                <Space>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => onEdit(record)}>编辑</a>
                </Space>
            ),
        },
    ];


    const onEdit = (record: any) => {
        showUserOperateModal()
        form.setFieldsValue({
            id: record.id,
            mchId: record.mchId,
            mchName: record.mchName,
            mchKey: record.mchKey,
            description: record.description,
        })
    }

    const getTableHeader = () => {
        return <div style={{textAlign: "right", margin: "0 15px 0 0",}}>
            <Button type='primary' onClick={showUserOperateModal}>新增商户号</Button>
        </div>
    }

    const showUserOperateModal = () => {

        setVisible(true)
    }

    const onUserOperateCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    const save = () => {
        form.validateFields()
            .then(values => {
                // 编辑
                if(values.id){
                    mchService.update(values)
                        .then(res => {
                            const data = res.data;
                            if (data.status === 0) {
                                message.success("编辑成功")
                                search()
                                onUserOperateCancel()
                            } else {
                                message.error(data.msg)
                            }
                        })
                        .catch(err => {
                            message.error("发生未知错误");
                        })
                }else{
                    // 新增
                    mchService.add(values)
                        .then(res => {
                            const data = res.data;
                            if (data.status === 0) {
                                message.success("新增成功")
                                search()
                                onUserOperateCancel()
                            } else {
                                message.error(data.msg)
                            }
                        })
                        .catch(err => {
                            message.error("发生未知错误");
                        })
                }

            })
            .catch(err => {
            })
    }


    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{x: 'max-content'}}
        />
        <Modal
            title={`编辑`}
            open={visible}
            onCancel={onUserOperateCancel}
            onOk={save}
        >
            <Form form={form} style={{}}>
                <Form.Item style={{display:'none'}} label={"ID"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"id"} initialValue={null}>
                    <Input placeholder='id'/>
                </Form.Item>
                <Form.Item label={"商户号ID"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"mchId"} initialValue={null}
                           rules={[{required: true, message: '请输入商户号ID'}]}>
                    <Input placeholder='商户号ID'/>
                </Form.Item>
                <Form.Item label={"商户号名称"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"mchName"} initialValue={null}
                           rules={[{required: true, message: '请输入商户号名称'}]}>
                    <Input placeholder='商户号名称'/>
                </Form.Item>
                <Form.Item label={"V2密钥"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"mchKey"} initialValue={null}
                           rules={[{required: true, message: '请输入商户号V2密钥'}]}>
                    <Input.Password placeholder='商户号V2密钥'/>
                </Form.Item>
                <Form.Item label={"备注"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"description"} initialValue={null}
                           rules={[{required: false, message: '请输入商户号名称'}]}>
                    <Input.TextArea placeholder='商户号备注'/>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}