import {httpService} from "../http-service";

export class MchService{

    list(){
        return httpService.post("/mch/list", {});
    }

    add(wxMch: any) {
        return httpService.post("/mch/add", wxMch);
    }

    update(wxMch: any) {
        return httpService.post("/mch/update", wxMch);
    }
}

export const mchService = new MchService();