import { Form, DatePicker, Space, Button, Row, Col, Select, message } from 'antd';
// import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { simService, vendorManageService } from '../../../../service';

const { RangePicker } = DatePicker
const { Option } = Select

interface ISearchFormProps {
    updatePayload: (params: IPayload) => void
    payload: IPayload
    searchLoading: boolean
}

export interface IPayload {
    importBatchList: string[]
    iccIdList: string[]
    vendorList: string[]
    status: 'activate' | 'inactive' | 'pause' | 'electrify_activate' | null
    isEnablePackage?: number
    activeStartTime?: number
    activeEndTime?: number
    importStartTime?: number
    importEndTime?: number
    expireStartTime?: number
    expireEndTime?: number
}

export const SearchForm = (props: ISearchFormProps) => {

    const [form] = Form.useForm();

    const [vendorList, setVendorList] = useState([])
    const [batchList, setBatchList] = useState([])

    useEffect(() => {
        getVendorList()
        getImportBatch()
    }, [])

    const onFinish = () => {
        const { importBatchList, iccIdList, vendorList, status, isEnablePackage, activeTime,
            importTime, expireTime } = form.getFieldsValue()
        const { updatePayload } = props
        let params: IPayload = {
            importBatchList,
            iccIdList,
            vendorList,
            status,
            isEnablePackage,
            activeStartTime: activeTime ? Number(activeTime[0].startOf('days').valueOf()) : undefined,
            activeEndTime: activeTime ? Number(activeTime[1].endOf('days').valueOf()) : undefined,
            importStartTime: importTime ? Number(importTime[0].startOf('days').valueOf()) : undefined,
            importEndTime: importTime ? Number(importTime[1].endOf('days').valueOf()) : undefined,
            expireStartTime: expireTime ? Number(expireTime[0].startOf('days').valueOf()) : undefined,
            expireEndTime: expireTime ? Number(expireTime[1].endOf('days').valueOf()) : undefined,
        }
        updatePayload && updatePayload(params)
    }

    // 获取卡商列表
    const getVendorList = () => {
        vendorManageService.getVendorList()
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    setVendorList(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    const getImportBatch = () => {
        simService.getImportBatch({ dealerId: localStorage.getItem("dealerId") })
            .then(res => {
                const responseMsg = res.data
                if (responseMsg.status === 0) {
                    let data = responseMsg.data.map((it: any) => {
                        return {
                            label: it,
                            value: it
                        }
                    })
                    setBatchList(data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch((err) => {
                message.error("网页发生错误,请联系管理员")
            })
    }

    const { searchLoading } = props
    return <div className="sim-list-form-wrap">
        <Form form={form} onFinish={onFinish}>
            <Row>
                <Col span={5}>
                    <Form.Item label={"导入批次"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"importBatchList"} initialValue={[]}>
                        {/* <AutoComplete
                            options={batchList}
                            placeholder="请输入"
                            filterOption={(inputValue: string, option: any) =>
                                option!.value.indexOf(inputValue) !== -1
                            }
                        /> */}
                        <Select
                            options={batchList}
                            placeholder='请选择'
                            mode='multiple'
                            maxTagCount={1}
                            maxTagTextLength={4}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"ICCID"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"iccIdList"} initialValue={[]}>
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            options={[]}
                            maxTagCount="responsive"
                            placeholder="请输入"
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"卡商"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"vendorList"} initialValue={[]}>
                        <Select placeholder={"请选择"} allowClear mode='multiple' maxTagCount={1} maxTagTextLength={4}>
                            {
                                vendorList.map((item: any) => {
                                    return <Option value={item.vendorName}>{item.vendorName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={"状态"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"status"} initialValue={null}>
                        <Select placeholder={"请选择"} allowClear>
                            <Option value="inactive">未激活</Option>
                            <Option value="electrify_activate">通电激活</Option>
                            <Option value="activate">已激活</Option>
                            <Option value="pause">停机</Option>
                            {/* <Option value="k3">已停机</Option>
                        <Option value="k4">待回收</Option> */}
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"是否有套餐"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"isEnablePackage"} initialValue={null}>
                        <Select placeholder={"请选择"} allowClear>
                            <Option value={1}>有套餐</Option>
                            <Option value={2}>无套餐</Option>
                            <Option value={3}>有赠送套餐</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Form.Item label={"入库时间"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"importTime"}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"激活时间"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"activeTime"}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={"到期时间"} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        name={"expireTime"}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={4} offset={5}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} htmlType={"submit"} loading={searchLoading}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                form.resetFields()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}