import {Button, Form, Input, message, Modal, Radio, Select, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {dealerManageService, mchService} from '../../../../service/admin';
import {IPayload} from '../SearchForm';

interface IDataListProps {
    payload: IPayload
    updateLoading: (loading: boolean) => void
    searchLoading: boolean
}

interface DealerManageDataType {
    id: string; // id
    key?: string;
    status?: string;
    dealerName?: string; // 经销商名称
    updateTime?: string; // 创建日期
    wxMch?: any;
}

export const DataList = (props: IDataListProps) => {

    const [form] = Form.useForm()

    const {payload, updateLoading, searchLoading} = props

    const [tableData, setTableData] = useState([])

    const [visible, setVisible] = useState<boolean>(false)

    const editInfo = useRef<DealerManageDataType | null>(null)

    const [allMch, setAllMch] = useState<any[]>([])

    useEffect(() => {
        // 查询逻辑
        getDealerList()
        // eslint-disable-next-line
    }, [payload])

    useEffect(() => {
        mchService.list()
            .then(res => {
                const data = res.data;
                if (data.status == 0) {
                    setAllMch(data.data)
                } else {
                    message.error(data.msg)
                }
            })
            .catch(err => {
                message.error("发生未知错误")
            })
    }, []);

    /**
     * 查询经销商列表
     */
    const getDealerList = (isHideResMsg?: boolean) => {
        updateLoading(true)
        dealerManageService.getDealerList(payload)
            .then(res => {
                updateLoading(false)
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    !isHideResMsg && message.success('查询成功')
                    setTableData(responseMsg.data)
                } else {
                    message.error(responseMsg.msg)
                }
            })
            .catch(err => {
                updateLoading(false)
                message.error("发生未知错误")
            })
    }

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "经销商ID"
        },
        {
            key: "status",
            dataIndex: "status",
            title: "状态",
            render: (_: any, record: any) => {
                if (record.status === "enable") {
                    return <span style={{color: "green"}}>启用</span>
                } else if (record.status === "disable") {
                    return <span style={{color: "red"}}>禁用</span>
                } else {
                    return record.status;
                }
            }
        },
        {
            key: "dealerName",
            dataIndex: "dealerName",
            title: "经销商"
        },
        {
            key: "wxMch",
            dataIndex: "wxMch",
            title: "商户号ID",
            render: (_: any, record: any) => {
                return record.wxMch?.mchId
            }
        },
        {
            key: "wxNam",
            dataIndex: "wxMch",
            title: "商户号名称",
            render: (_: any, record: any) => {
                return record?.wxMch?.mchName
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                <Space>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => onEdit(record)}>编辑</a>
                </Space>
            ),
        },
    ];

    const onEdit = (record: DealerManageDataType) => {
        editInfo.current = record
        showUserOperateModal()
        form.setFieldsValue({
            dealerName: record.dealerName,
            status: record.status,
            wxMch: record?.wxMch?.mchId
        })
    }

    const getTableHeader = () => {
        return <div style={{textAlign: "right", margin: "0 15px 0 0",}}>
            <Button type='primary' onClick={showUserOperateModal}>新增经销商</Button>
        </div>
    }

    const showUserOperateModal = () => {
        setVisible(true)
    }

    const onUserOperateCancel = () => {
        editInfo.current = null
        setVisible(false)
        form.resetFields()
    }

    const onUserOperateOk = () => {
        form.validateFields().then(() => {
            const params = form.getFieldsValue()
            if(params.wxMch){
                params.wxMch = {mchId: params.wxMch}
            }
            // console.log('params: ',params)
            let promise = !editInfo.current ? dealerManageService.addDealer({...params}) :
                dealerManageService.updateDealer({...params, id: editInfo.current?.id})
            promise.then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    message.success('操作成功')
                    setVisible(false)
                    editInfo.current = null
                    form.resetFields()
                    getDealerList(true)
                } else {
                    message.error(responseMsg.msg)
                }
            })
                .catch((err) => {
                    message.error("网页发生错误,请联系管理员")
                })
        })
    }

    return <div>
        <Table
            rowKey='id'
            columns={columns}
            dataSource={tableData}
            title={getTableHeader}
            loading={searchLoading}
            scroll={{x: 'max-content'}}
        />
        <Modal
            title={`${editInfo.current ? '编辑' : '新增'}经销商`}
            open={visible}
            onCancel={onUserOperateCancel}
            onOk={onUserOperateOk}
        >
            <Form form={form}>
                <Form.Item label={"经销商全名"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"dealerName"} initialValue={null}
                           rules={[{required: true, message: '请输入经销商全名'}]}>
                    <Input placeholder='请输入'/>
                </Form.Item>
                <Form.Item label={"状态"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"status"} initialValue='enable'
                           rules={[{required: true, message: '请选择状态'}]}>
                    <Radio.Group>
                        <Radio value={'enable'}>启用</Radio>
                        <Radio value={'disable'}>禁用</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={"商户号"} labelCol={{span: 5}} wrapperCol={{span: 19}}
                           name={"wxMch"} initialValue={null}
                           rules={[{required: true, message: '商户号'}]}>
                    <Select options={allMch.map(mch => {
                        return {label: mch.mchName, value: mch.mchId}
                    })}/>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}